export var PortfolioData = [
	{
		id: 1,
		name: 'Felinda OÜ',
		image: 'felinda/background.png',
		tags:['Web','Wordpress', 'Design', 'SEO'],
		field: 'Tailoring company, clothes fitting, work clothes making and custom tailoring.',
		description:'Felinda needed a website where clients could make custom orders with their needed size, color and texture, aswell as make preexisting orders on' +
			'products that Felinda has already pre-made.',
		development:'Felinda got wordpress based website with custom ecommerce system since, they dont need any payments made on their site or woocommerce features.' +
		' Custom design was made with additional mailing services and order template logic.',
		media:[
			'felinda/felinda1.png',
			'felinda/felinda2.png',
			'felinda/felinda3.png'
		]
	},
	{
		id:2,
		name: 'Wisora Software',
		image: 'wisora/bg.jpg',
		tags:['Design', 'Web', 'SEO'],
		field: 'Budgeting, Forecasting, Consolidation & eleminations made for people.',
		description: 'Client needed website to promote and via SEO sell financial software for wider community.',
		development: 'Custom design was made for client with pure html for faster seo optimization, since client liked html editing.' +
			' Also SEO optimization for website and easy content editing blocks included in via php.',
		media:[
			'wisora/wisora.png',
			'wisora/wisora_2.png',
			'wisora/wisora_3.png',
			'wisora/wisora_4.png'
		]
	},
	{
		id: 3,
		name: 'Softbuilder',
		image: 'softbuilder/background.jpg',
		tags:['Design', 'Web', 'SEO'],
		field: 'Budgeting, Forecasting, Consolidation & Elminiations made for people.',
		description: 'Client needed website to promote and via SEO sell financial software for wider community.',
		development: 'Custom design was made for client with pure html for faster seo optimization, since client liked html editing.' +
			' Also SEO optimization for website and easy content editing blocks included in via php.',
		media:[
			'softbuilder/softbuilder1.png',
			'softbuilder/softbuilder2.png',
			'softbuilder/softbuilder3.png',
			'softbuilder/softbuilder4.png'
		]
	},
	{
		id: 4,
		name: 'Omaveeb',
		image: 'omaveeb/background.jpg',
		tags:['Design', 'Web'],
		field: 'Web agency, creative designs, coding and infosystems.',
		description:'Website was needed to reach out clients and find new customers, to design and create beautiful websites.',
		development: 'Designed the page from zero and made seo optimizations from the start. Data filling, content creation and content analysis.',
		media:[
			'omaveeb/omaveeb1.png',
			'omaveeb/omaveeb2.png',
			'omaveeb/omaveeb3.png',
			'omaveeb/omaveeb4.png',
			'omaveeb/omaveeb5.png',
		]
	},
	{
		id:5,
		name: 'Introdac',
		image: 'introdac/cover.png',
		tags:['Design', 'Web', 'Wordpress'],
		field: 'Viber ad agency',
		description:'Introdac ad service wanted a website, where they could list pricelist and contact data.',
		development: 'Website template was made, later on integrated on wordpress, translation and seo features added.',
		media:[
			'introdac/introdac1.png',
			'introdac/introdac2.png',
			'introdac/introdac3.png',
			'introdac/introdac4.png',
		]
	},
	{
		id: 6,
		name: 'Wortex',
		image: 'wortex/background.jpg',
		tags:['Design', 'PHP', 'MySQL', 'Custom'],
		field: 'E-Sports, gaming community, Airsoft',
		description:'Client needed community site, where they could list upcoming events, e-sport championships and create clans for lan parties.',
		development:'Advanced team creation and user registration system was made aswell as template and frontpage for event listings. ' +
			'Frontpage covered news, running live championships, streams and latest teams where users could register and join that were' +
			'taking place in lan parties. Full site was created with html, js, css, php, mysql. Lastly i have made several VueJS, Api to frontend ' +
			'systems that communicated in gta 5 multiplayer server within eachother, pictures are down below. System like Inventory ui, general ui, character' +
			' selection, creation, phone ui etc.',
		media:[
			'wortex/web.png',
			'wortex/gta5/1.png',
			'wortex/gta5/2.png',
			'wortex/gta5/3.png',
			'wortex/gta5/4.png',
			'wortex/gta5/5.png',
			'wortex/gta5/6.png',
			'wortex/gta5/7.png',
			'wortex/gta5/8.png'
		]
	},
	{
		id: 7,
		name: 'The Revelations',
		image: 'therevelations/bg.jpg',
		tags:['UI', 'Unity 3d', 'API development', 'C#', 'PHP'],
		field: 'Massive Multiplayer Zombie Apocalypse survival game',
		description:'API and game selling, news, blog site.',
		development:'Game API, version management and news, product selling was made. Aswell as design for the website.',
		media:[
			'therevelations/web.png'
		]
	},
	{
		id: 8,
		name: 'Plastipaint',
		image: 'plastipaint/bg.jpg',
		tags:['Design', 'Web', 'Wordpress'],
		field: 'Car wrapping, plastic soft painting company',
		description:'Client needed simple website, where they could publish their done work.',
		development: 'Custom wordpress template and portfolio listing plugin was created.',
		media:[
			'plastipaint/web.png'
		]
	},
	{
		id: 9,
		name: 'Mapsons',
		image: 'mapsons/bg.jpg',
		tags:['Design', 'PHP', 'MySQL', 'Custom', 'Google'],
		field: 'Local restraunts, attractions and guides for tourists.',
		description:'Client had early setup of the project and needed it to be finished.',
		development:'Finished previous work of the software, added paypal integration for companies to register their firm on the map' +
			'and published/deploy system.',
		media:[
			'mapsons/web.png'
		]
	}
]
