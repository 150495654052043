<template>
  <div class="header">
    <div class="container">
        <div class="row">
            <div class="col-md-2 text-left">
                <div class="logo-box">
                    Andrus Edala

                </div>
                <div class="job-title">Full-stack developer</div>
            </div>
            <div class="col-md-10 custom-nav">
                <ul>
                    <li><router-link to="/" exact>Home</router-link></li>
                    <li><router-link to="/projects" >Portfolio</router-link></li>
                    <li><router-link to="/shop" >Freebies & Shop</router-link></li>
                </ul>
            </div>
        </div>
        
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
.header{
    padding-top:20px;
}
.logo-box{
    color:#CC5D22;
    font-size:16px;
}
.logo-box .letters{

}

.custom-nav ul li{
    list-style-type:none;
    display:inline-block;
    margin-right:25px;
}
.custom-nav ul li a{
    color:#000;
    text-decoration:none;
}
.custom-nav ul li a.active, .custom-nav ul li a.router-link-active{
    color:#0A5DE2;
    border-top:2px solid #0A5DE2;
    padding-top:5px;
}

</style>
