<template>
	<div class="page" data-page="frontpage">
		<PageHeader
				lastUpdate="6. July 2020 01:00"
				title="Hi! I'm Andrus"
				cover="frontpage.png"
		>
			web developer, hobbist game developer, hobbist designer, it enthuasiast.
			I like to occasionally do freelance work and help with interesting projects, need extra hand?
			Send me a message down below.
			<div class="cv-buttons">
				<a href="#" class="base-btn btn-orange" v-b-modal.modal-1>Contact me</a>
				<a href="#" class="base-btn btn-blue">Download CV (EE)</a>
				<a href="#" class="base-btn btn-black">Download CV (EN)</a>
			</div>
		</PageHeader>
		<AboutMe/>
		<Skills/>
		<Overview/>
	</div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import AboutMe from "../components/PageSections/Frontpage/AboutMe";
import Skills from "../components/PageSections/Frontpage/Skills";
import Overview from "../components/PageSections/Frontpage/Overview";


export default {
	name: 'Frontpage',
	components:{
		PageHeader,
		AboutMe,
		Skills,
		Overview
	},
	props: {
	
	},
	data() {
		return {
			jobs:[
				{
					company:'Bauwise OÜ',
					position:'full-stack developer',
					since:'2 November 2019',
          to: 'Present',
					current:true,
				},
        {
          company:'Andevis AS 2',
          position:'full-stack developer',
          since:'6 August 2019',
          to:'30 October 2020',
          current:false,
        },
				{
					company:'Softbuilder OÜ',
					position:'Back-end developer',
					since:'13 October 2015',
					current:false,
				},
				{
					company:'Freelance',
					position:'Freelance front/back-end developer',
					since:'',
					current:false,
				}
			],
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
.latest-posts{
	padding-top:50px;
	padding-bottom:50px;
}
.latest-projects{
	border-top:1px solid #fafafa;
	padding-top:50px;
}
.intro{
	padding-top:50px;
	padding-bottom:50px;
	font-family:'Roboto', sans-serif;    
}
.intro h2{
	font-size:3.5em;
	color:#000
}
.intro h3{
	font-size:20px;
    color:rgba(0, 0, 0, 0.952);
}
.intro .descriptive{
	line-height:35px;
	font-size:18px;
	color:#000
}
.footer{
	padding-top:50px;
}
.custom-nav ul li{
	list-style-type:none;
	display:inline-block;
	margin-right:25px;
}
.custom-nav ul li a{
	color:#dbdbdb;
	text-decoration:none;
	text-align:center;
}
.custom-nav ul li a.active{
	color:#dbdbdb;
}
.current-position{
	padding-top:10px;
	font-size:16px;
}

.cv-buttons{
	padding-top:30px;
}
.base-btn{
	padding-left:15px;
	padding-right:15px;
	padding-top:8px;
	padding-bottom:8px;
	text-align:center;
	color:#fff;
	border-radius:40px;
	font-size:13px;
	margin-right:10px;
	font-family: 'Arial Rounded MT Bold';
}
.btn-orange{
	background-color: #F37021;
}
.btn-grey{
	background-color: #4A4A4A;
}
.btn-blue{
	background-color:#5885EB;
}
.btn-black{
	background-color:#000;
}

.blue-title{
	color:#5885EB;
	text-transform: uppercase;
	font-weight:bold;
	font-size:24px;
}

</style>
