<template>
	<div class="footer">
		<div class="explore">
			<h3>Have an idea, project or an offer?</h3>
			<h5>Hit me up, if you have any ideas, projects you want help with.</h5>
			<div class="contact-button">
				<b-button class="base-btn btn-blue" v-b-modal.modal-1>Send me a message</b-button>

				<b-modal id="modal-1" title="Contact me" ref="footer-modal" hide-footer>
					<form method="post">
						<div class="row">
							<div class="col-md-6">
								<b-form-group
										id="fieldset-1"
										description="Please let me know who is contacting me, a person or an organization"
										label="What's your name?"
										label-for="input-1"
										:invalid-feedback="validateName"
										:valid-feedback="validateName"
										:state="nameState"
								>
									<b-form-input id="input-1" v-model="name" :state="nameState" trim></b-form-input>
								</b-form-group>
							</div>
							<div class="col-md-6">
								<b-form-group
										id="fieldset-2"
										description="Leave your contact details, so i can respond to your e-mail or phone call."
										label="E-mail / Phone"
										label-for="input-2"
										:invalid-feedback="validatePhone"
										:valid-feedback="validatePhone"
										:state="phoneState"
								>
									<b-form-input id="input-2" v-model="phone" :state="phoneState" trim></b-form-input>
								</b-form-group>
							</div>
							<div class="col-md-12">
								<b-form-group
										id="fieldset-3"
										description="Brief topic what you're writing to me about."
										label="Topic"
										label-for="input-3"
										:invalid-feedback="validateTopic"
										:valid-feedback="validateTopic"
										:state="topicState"
								>
									<b-form-input id="input-3" v-model="topic" :state="topicState" trim></b-form-input>
								</b-form-group>
								<b-form-group
										id="fieldset-4"
										description="What's your inquiry or offer."
										label="Message"
										label-for="input-4"
										:invalid-feedback="validateMessage"
										:valid-feedback="validateMessage"
										:state="messageState"
								>
									<b-form-textarea id="input-4" v-model="message" :state="messageState"
                                     trim></b-form-textarea>
								</b-form-group>
								<b-button class="mt-2" variant="primary" block @click="toggleModal">Send me a
									message
								</b-button>
							</div>
						</div>

					</form>
				</b-modal>
			</div>
		</div>
		<div class="copyright text-center">
			2020 - Designed and coded by Andrus Edala
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Footer',
		props: {
			msg: String
		},
		computed: {
            validateName: function () {
                if(this.name.length > 3){
                    return '';
                } else if(this.name.length >0) {
                    return 'Name must be atleast 3 characters.';
                } else {
                    return 'Pleas enter your name or your company name.';
                }
            }, nameState() { return this.name.length > 3 },

            validatePhone: function () {
                if(this.phone.length > 4){
                    return '';
                } else if(this.phone.length >0) {
                    return 'Phone / Email must be atleast 6 characters.';
                } else {
                    return 'Pleas enter phone number (000 00 00) or e-mail address.';
                }
            },phoneState() { return this.phone.length > 6 },

            validateTopic: function () {
                if(this.topic.length > 6){
                    return '';
                } else if(this.topic.length >0) {
                    return 'Topic must be atleast 6 characters.';
                } else {
                    return 'Pleas enter topic of your message.';
                }
            },topicState() { return this.topic.length > 6 },

            validateMessage: function () {
                if(this.message.length > 50){
                    return '';
                } else if(this.message.length >0) {
                    return 'Your message must contain atleast 50 characters, for no spam purposes.';
                } else {
                    return 'Pleas enter content of your message.';
                }
            },messageState() { return this.message.length > 50 },

		},
		data() {
			return {
				name: '',
				phone: '',
				topic: '',
				message: '',
			}
		},
		methods: {
			hideModal() {
				this.$refs['footer-modal'].hide()
			},
			toggleModal() {
				// We pass the ID of the button that we want to return focus to
				// when the modal has hidden
				this.$refs['footer-modal'].toggle('#toggle-btn')
			}
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

	.copyright {
		padding-top: 50px;
		color: #dbdbdb;
	}

	.footer {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.explore {
		background-image: url('/img/explore.svg');
		background-repeat: no-repeat;
		background-size: 850px;
		min-height: 500px;
		text-align: center;
		background-position: center center;
	}

	.explore h3 {
		padding-top: 150px;
	}

	.explore h5 {
		font-size: 15px;
	}

	.explore .contact-button {
		padding-top: 150px;
	}

</style>
