<template>
	<div class="skills">
		<div class="container">
			<h3 class="text-left blue-title">What i'm working with</h3>
			<div class="row">
				<div class="col-md-8">
					<div class="skills-listing">
						<div class="row">
							<div class="col-md-6 skill" v-for="skill in skills" :key="skill.priority">
								<div class="skill-bubble">{{skill.priority}}</div>
								<h4>{{skill.title}}</h4>
								<div class="grey-seperator"></div>
								<div class="overview">{{skill.subtitle}}</div>
								<div class="grey-seperator"></div>
								<div class="details">{{skill.content}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 skills-thumbnail">
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'AboutMe',
		props: {
			msg: String
		},
		data() {
			return {
				skills:[
					{
						priority:1,
						title:'Backend',
						subtitle:'PHP, MySQL, API, Frameworks',
						content:'Wordpress, Rest API, Directo API, Microsoft API, Google API, Unity 3D, Omniva API,' +
							' SAP API, Symfony, PHP, MySQL'
					},
					{
						priority:2,
						title:'Frontend',
						subtitle:'HTML, CSS, Javscript, Frameworks',
						content:'Vue, Angular, Bootstrap, Foundation, jQuery, Ajax, Javscript, npm, composer,' +
							'sass, webpack, twig'
					},
					{
						priority:3,
						title:'Utilities',
						subtitle:'Task, Project, Version management',
						content:'Trello, Jira, Slack, Git, GitLab, BitBucket, Jenkins, Docker, Toggl, xMind, Redmine'
					},
					{
						priority:4,
						title:'Design',
						subtitle:'Mockups, Logos, Schematics',
						content:'xMind, Photoshop, Adobe XD, Illustrator'
					},
				]
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
	.skills{
		padding-top:50px;
	}
	.skills-listing{
		padding-top:50px;
	}
	.skill{
		padding-bottom:40px;
	}
	.skill .skill-bubble{
		background-color:#5DEB58;
		width:35px;
		padding-top:9px;
		font-size:13px;
		font-weight:bold;
		height:34px;
		margin: 0 auto;
		border-radius:40px;
		color:#fff;
		text-align:center;
	}
	.skill h4 {
		font-size:17px;
		font-weight:bold;
		padding-top:20px;
		padding-bottom:5px;
	}
	.skill .overview{
		padding-top:10px;
		padding-bottom:10px;
		font-weight:bold;
		font-size:15px;
	}
	.skill .details{
		padding-top:10px;
		font-size:14px;
	}
	.grey-seperator{
		width:2px;
		height:20px;
		background-color:#ececec;
		margin: 0 auto;
	}
	.skills-thumbnail{
		background-image:url('/img/skills.png');
		background-position: -270px 65px;
		background-repeat: no-repeat;
	}
</style>
