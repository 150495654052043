<template>
	<div class="col-md-4">
		<div class="project">
			<div class="image" :style="'background-image:url(/img/portfolio/'+image+');background-size:cover;'">
				<div class="cover">
					<div class="overlay">
						<div class="name">
							<a :href="'/project/'+id">{{name}}</a>
						</div>
						<div class="done-work">
							<div class="cv-buttons done-work">
								<a href="#"
									v-for="(tag) in tags"
									:key="tag"
									class="base-btn btn-grey">
									{{tag}}
								</a>
							</div>
						</div>
						<div class="description">
							<slot></slot>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PortfolioItem',
		props: {
			id:Number,
			name: String,
			description: String,
			author: String,
			date: String,
			image: String,
			tags: Array
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
	.done-work{
		font-size:11px;
		padding-top:30px;
	}
	.overlay .description{
		padding-top:90px;
		padding-left:20px;
		padding-right:20px;
		font-size:17px;

	}
</style>
