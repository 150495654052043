import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Frontpage from './pages/Frontpage'
import Project from "./pages/Project/Project";
import ProjectsListing from './pages/Project/ProjectListing'
import Shop from "./pages/Shop";

const router = new VueRouter({
	mode: 'history',
	hash: false,

	routes: [
        {
			path: '/',
			name: 'home',
			component: Frontpage
		},
		{
			path: '/projects',
			name: 'projects',
			component: ProjectsListing
		},
		{
			path: '/project/:id',
			name: 'project',
			component: Project
		},
		{
			path: '/shop',
			name: 'shop',
			component: Shop
		},
    ]
})

export default router
