<template>
	<div class="page" data-page="projects">
		<PageHeader
				title="Freebies & Shop"
				cover="shop.svg"
		>
			I will publish some free wordpress templates, plugins, php related snippets
			and open source projects.
			<div class="cv-buttons">
				<a href="#" class="base-btn btn-orange">All</a>
				<a href="#" class="base-btn btn-grey">Design</a>
				<a href="#" class="base-btn btn-grey">Freebies</a>
				<a href="#" class="base-btn btn-grey">Scripts</a>
				<a href="#" class="base-btn btn-grey">Wordpress</a>
			</div>
		</PageHeader>

		<div class="container">
			<div class="shop">
				<div class="no-products">
					<div class="emoji-sad">:(</div>
					<h6 class="emoji-label">Sorry, no products have been published yet. <br/> Please check back
						soon</h6>
				</div>

				<h2>Top freebies</h2>
				<div class="product-group">

				</div>
				<div class="row">
					<div class="col-md-4">
						<Product
						name="Corporate template"
						/>
					</div>
					<div class="col-md-4"><Product/></div>
					<div class="col-md-4"><Product/></div>
				</div>
				<h2>Top paid</h2>
				<div class="row">
					<div class="col-md-4">
						<Product
								name="Corporate template"
						/>
					</div>
					<div class="col-md-4"><Product/></div>
					<div class="col-md-4"><Product/></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import PageHeader from "../components/PageHeader";
	import Product from "../components/Product";

	export default {
		name: 'Shop',
		components:{
			PageHeader,
			Product
		},
		props: {
			msg: String
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.no-products{
		font-size:35px;
		padding-top:50px;
		padding-bottom:100px;
	}
	.no-products .emoji-label{
		font-size:20px;
		line-height:30px;
	}
	.meta-heart{
		margin:5px;
	}
	.meta-icon{
		padding-left:30px;
		padding-right:30px;
		padding-top:10px
	}
	.shop h2{
		font-size:41px;
		font-weight:bold;
		text-align:left;
		border-bottom:1px solid #e9e9e9;
	}
	.shop-item{
		background-color:#fafafa;
		min-height:280px;
		border-radius:8px;
		padding:5px;
		padding-top:15px;
		margin-top:30px;
		margin-bottom:30px;
	}
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
</style>
