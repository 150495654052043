<template>
	<div class="page-header">
		<div class="container">
			<div class="row">
				<div class="col-md-6 text-left title-pad">
					<h1>{{title}}</h1>
					<div class="page-description">
						<slot></slot>
					</div>
					<div class="last-update" v-if="lastUpdate != null"> Portfolio last updated: {{lastUpdate}}</div>
				</div>
				<div class="col-md-6">
					<img class="page-header-thumbnail" :src="'/img/' + cover" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PageHeader',
		props: {
			title: String,
			description: String,
			cover: String,
			lastUpdate: String
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.page-header-thumbnail{
		text-align:right;
	}
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
	.page-header{
		padding-top:50px;
	}
	.page-description{
		padding-top:10px;
	}
	.title-pad{
		padding-top:50px;
	}
	.last-update{
		padding-top:20px;
		color:#dbdbdb;
		font-size:13px;
	}

</style>
