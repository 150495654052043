<template>
	<div class="shop-item">
		<div class="overlay">
			<div class="row">
				<div class="col-md-6 text-left">
					<div class="meta-icon">
						<img src="/img/heart.png" width="18" style="background-position: top center;">
						<span class="meta-heart"> {{likes}}</span>
					</div>
				</div>
				<div class="col-md-6 text-right">
					<div class="meta-icon" v-if="price == null">
						<a :href="downloadLink"><img src="/img/download.png" width="18"></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Product',
		props: {
			name: String,
			description: String,
			price: Number,
			published: Boolean,
			likes: Number,
			downloadLink: String,
			category: Object

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');


</style>
