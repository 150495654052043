<template>
	<div class="page" data-page="projects">
		<PageHeader
				title="My work"
				cover="portfoliosvg.svg"
		>
			I like to occasionally do freelance work and help with interesting projects, need extra hand?
			Here are the listing of all archive and present work.
			<div class="cv-buttons" style="display:none;">

				<a href="#" class="base-btn btn-grey">Web</a>
				<a href="#" class="base-btn btn-grey">Backend</a>
				<a href="#" class="base-btn btn-grey">Snippets</a>
				<a href="#" class="base-btn btn-grey">E-commerce</a>
				<a href="#" class="base-btn btn-grey">Design</a>
			</div>
		</PageHeader>
		<div class="project-listing">
			<div class="row">
				<PortfolioItem
						v-for="item in portfolio"
						:id="item.id"
						:key="item.id"
						:name="item.name"
						:image="item.image"
						:tags="item.tags">
					{{item.field}}
				</PortfolioItem>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from "../../components/PageHeader";
	import PortfolioItem from "../../components/PortfolioItem";
	import { PortfolioData } from '../../data/portfolio.js';

	export default {
		name: 'ProjectListing',
		components:{
			PageHeader,
			PortfolioItem
		},
		props: {
			msg: String
		},
		data(){
			return {
				selectedCategory:1,
				portfolio: PortfolioData,
				categories: [
					{id:1, name:'All'},
					{id:2, name:'Web'},
					{id:3, name:'Backend'},
					{id:4, name:'Snippets'},
					{id:5, name:'E-commerce'},
					{id:6, name:'Design'},
				]
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	/* Projects */
	.project .overlay .name a{
		padding:20px;
		font-size:30px;
		color:#fff;
		font-weight:bold;
		font-family:'Roboto',sans-serif;
	}
	.project-listing{
		padding-left:25px;
		padding-right:25px;
	}
	.project{
		min-height:300px;
	}
	.project .image{
		min-height:450px;
		transition:.2s;
		transition-timing-function: ease-in-out;
		z-index:-1;
	}
	.project .image:hover{
		transition:.2s;
		transition-timing-function: ease-in-out;
		transform:scale(0.95);
		z-index:999;
	}
	.project .cover{
		color:#fff;
		background: #8e9eab;  /* fallback for old browsers */
		opacity:0.8;
		transition:.2s;
		transition-timing-function: ease-in-out;
	}
	.project .cover:hover{
		opacity:0.45;
	}
	.project .image:hover .name{
		color:#000;
	}
	.project .cover:hover .overlay{
		opacity:1;
	}
	.project .cover .overlay{
		min-height:450px;
		padding-top:140px;
		font-size:25px;
	}
	.project-filters{
		text-align:left;
		padding-top:50px;
	}
	.project-filters ul{
		padding:0;
	}
	.project-filters ul li{
		display:inline-block;
		margin-right:20px;
	}
	.project-filters ul li a{
		color:#000;
		text-decoration: none;
	}
	.project-filters ul li a.active{
		color:#000;
		border-bottom:2px solid #000;
	}
	.project-filters ul li a:hover{
		transition:.2s;
		border-bottom:2px solid #000;
	}
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
	.project-listing{
		padding-top:50px;
	}
	.project{
		margin-bottom:50px;
	}
	.custom-nav ul li{
		list-style-type:none;
		display:inline-block;
		margin-right:25px;
	}
	.custom-nav ul li a{
		color:#dbdbdb;
		text-decoration:none;
		text-align:center;
	}
	.custom-nav ul li a.active{
		color:#dbdbdb;
	}

	.page-header-thumbnail{
		width:66%;
	}


</style>
