<template>
	<div class="overview">
		<div class="container overview-container">
			<div class="row justify-content-center">
				<div class="col-md-4">
					<h5>Current employment</h5>
					<div class="current-job" v-for="job in jobs" :key="job.company">
						<div class="job" v-if="job.current == true">
							<div class="row">
								<div class="col-md-2 text-center">
									<img :src="'/img/jobs/'+job.logo+'.png'">
								</div>
								<div class="col-md-10 text-left">
									<b>{{job.company}}</b>
									<span class="position">{{job.position}}</span>
									<span class="since">since {{job.since}}
										<span class="present" v-if="job.current"> - Present</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<h5>Latest work</h5>
					<div class="nothing-here ">
						<div class="row">
							<div class="col-md-2">
								<div class="emoji-sad">:(</div>
							</div>
							<div class="col-md-10">
								<h6 class="emoji-label">Sorry, nothing here yet.</h6>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<h5>Recent activity</h5>
					<div class="nothing-here ">
						<div class="row">
							<div class="col-md-2">
								<div class="emoji-sad">:(</div>
							</div>
							<div class="col-md-10">
								<h6 class="emoji-label">Sorry, nothing here yet.</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Overview',
		props: {
			msg: String
		},
		data() {
			return {
        jobs:[
          {
            company:'Bauwise OÜ',
            position:'full-stack developer',
            since:'2 November 2019',
            to: 'Present',
            current:true,
          },
          {
            company:'Andevis AS 2',
            position:'full-stack developer',
            since:'6 August 2019',
            to:'30 October 2020',
            current:false,
          },
          {
            company:'Softbuilder OÜ',
            position:'Back-end developer',
            since:'13 October 2015',
            current:false,
          },
          {
            company:'Freelance',
            position:'Freelance front/back-end developer',
            since:'',
            current:false,
          }
        ],
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.position{
		display:block;
	}
	.since{
		font-size:11px;
	}
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
	.emoji-sad{
		font-size:25px;
		display:block;
		text-align:center;
	}
	.emoji-label{
		font-size:16px;
		padding-top:8px;
	}
	.nothing-here{
		color: #e6e6e6;
		padding-top:35px;
		text-align: left;
	}
	.overview-container{
		border-top:1px solid #ececec;
		padding-top:50px;
		margin-top:50px;
	}
	.overview h5{
		text-align:left;
		text-transform: uppercase;
		font-weight: bold;
		font-size:20px;
	}
	.job{
		padding-top:30px;
	}
	.job img{
		padding-top:10px;
	}
	.job h6{
		font-style:italic;
		font-size:15px;
		font-weight:bold;
	}
	.job h7{
		font-size:14px;
	}
	.job h8{
		font-size:13px;
		color:grey;
		display:block;
	}

</style>
