<template>
  <div id="app">
      <Header></Header>
        <router-view></router-view>
      <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'app',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
    @import './assets/css/mobile.css';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
    .custom-nav{
        text-align: right;
    }
    .modal-content{
        border: 0 !important;
        border-radius: 15px !important;
        box-shadow: 0px 1px 10px 0px #424242;
    }
</style>
