<template>
	<div class="about-me">
		<div class="container">
			<h3 class="text-left blue-title">About me</h3>
			<div class="self-description text-left">
				I have been an IT enthusiast since i was 13. I started learning and developing servers/applications  early on, began with game
				server development in lua/pawn, afterwards moved onto web development with html,css, js technologies.
				After a while being able to create own websites, i wanted to add functionality to them like login,
				registration, logs system so i started learning and playing around with PHP and MySQL. I liked PHP
				and web development so much, i have created more than 300 self hobby projects, made 20+ websites for
				relatives/friends and same amount of clients freelanced to. I constantly have fun little ideas and
				projects i keep coming up to and also help friends develop their projects or find an interesting
				startup that needs quick help.
			</div>
			<div class="features">
				<div class="row">
					<div class="col-md-6 feature" v-for="feature in features" :key="feature.title">
						<h4>{{feature.title}}</h4>
						{{feature.text}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AboutMe',
		props: {
			msg: String
		},
		data() {
			return {
				features:[
					{
						title:'Dreamer',
						text:'I dream big, i like to create and modify things to near perfect. \n' +
							'I like planning things out and do things step by step \n' +
							'workout my own workflow to maximize productivity.\n' +
							'Scheming and mindstorming on whiteboard is fun for me.'
					},
					{
						title:'Time & Project management',
						text:'I have had experience lately with managing project\n' +
							'and developing, mostly solo, communicating\n' +
							'with customers, finding out what they need and\n' +
							'making sure new feature needed, solves their problem.'
					},
					{
						title:'Creative thinking',
						text:'When i’m invested, i always look ways to improve existing\n' +
							'software from start to bottom. Is the interface usable, does it\n' +
							'look clean and understandable, is it functional. Does it meet\n' +
							'end goals of the user. Is there any features, that would increase\n' +
							'the usability and usefulness.'
					},
					{
						title:'Team player / friendly',
						text:'I’m very friendly once i get to know people. Usually\n' +
							'i blend in very well with others, but i can work solo aswell.\n' +
							'Employers have told that i fit in well with others\n' +
							'and co-workers enjoy working with me.'
					},
					{
						title:'Always trying to learn',
						text:'My goal is to keep my mind sharp, learning something new \n' +
							'each week.I like the idea of planning and taking responsibility\n' +
							'of development processes. When i am greeted with a new\n' +
							'complicated software, i take it in and try to learn and gather \n' +
							'as much experience with it as i can.'
					},
					{
						title:'Nature buddy / calm',
						text:'I travel often to Saaremaa to my country side home. \n' +
							'I like the nature and peacefulness there. I’m a nature buddy, \n' +
							'who loves fresh air, sun, discgolf and camping. \n' +
							'I got the camping bug when i were serving the Estonian military\n' +
							'service. Now i cant get it out of my system.'
					}
				]
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
	.about-me{
		padding-top:70px;
	}
	.about-me .self-description{
		padding-top:10px;
		font-family: 'Roboto';
		line-height:35px;
	}
	.about-me .features{
		padding-top:50px;
		text-align: left;
	}
	.about-me .feature h4{
		text-transform: uppercase !important;
		font-family:'Arial Rounded MT Bold';
		font-size:17px;
		padding-bottom:20px;
	}
	.about-me .feature{
		font-family: 'Roboto';
		line-height:28px;
		font-size:14.5px;
		padding-bottom:30px;
	}
</style>
